/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/bxslider@4.2.13/dist/jquery.bxslider.min.js
 * - /npm/object-fit-images@3.2.3/dist/ofi.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery.maskedinput@1.4.1/src/jquery.maskedinput.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
